<template>
  <div class="menu" :class="{opened}" v-on-clickaway="close">
    <div class="menu-entry">
      <icon icon="ellipsis-h" size="2x" @click="opened = !opened"/>
    </div>
    <div v-if="opened" class="menu-entry" @click="resetGameSelected">
      <icon icon="redo-alt" class="fa-fw"/> {{ $t('resetGame') }}
    </div>
    <div v-if="opened" class="menu-entry" @click="backToHomeSelected">
      <icon icon="home" class="fa-fw"/> {{ $t('backToHome') }}
    </div>
    <div v-if="opened" class="menu-entry" @click="$router.push({name: 'viewStatistics'})">
      <icon icon="list-ol" class="fa-fw"/> {{ $t('statistics') }}
    </div>
    <div v-if="opened" class="menu-entry" @click="$router.push({name: 'managePlayers'})">
      <icon icon="user-edit" class="fa-fw"/> {{ $t('managePlayers') }}
    </div>
    <div v-if="opened && $i18n.locale !== 'de'" class="menu-entry" @click="(opened = false) & ($i18n.locale = 'de')">
      <icon icon="globe" class="fa-fw"/> Deutsche Version
    </div>
    <div v-if="opened && $i18n.locale !== 'en'" class="menu-entry" @click="(opened = false) & ($i18n.locale = 'en')">
      <icon icon="globe" class="fa-fw"/> English Version
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex";
import {mixin as clickAway} from 'vue-clickaway'

export default {
  data() {
    return {
      opened: false
    }
  },
  methods: {
    ...mapActions(['reset', 'saveGame']),
    resetGame() {
      return this.saveGame().then(() =>
          this.reset()
      )
    },
    resetGameSelected() {
      this.opened = false
      this.resetGame().then(() => {
        if (this.$route.name !== 'addToPile2')
          this.$router.push({name: 'addToPile2'})
      })
    },
    backToHomeSelected() {
      this.opened = false
      this.resetGame().then(() => this.$router.push({name: 'home'}))
    },
    finalScoreSelected() {
      this.reset().then(() => this.$router.push('/').then(() => this.opened = false))
    },
    close() {
      this.opened = false
    }
  },
  mixins: [clickAway]
}
</script>
<style lang="scss" scoped>
.menu {
  position: absolute;
  display: flex;
  flex-direction: column-reverse;
  bottom: 2%;
  left: 2%;
  cursor: pointer;
  padding: 6px;
  color: white;
  z-index: 9999;

  &.opened {
    background-color: #FFFFFFAA;
    border-radius: 3px;
    color: black;
  }
}

.menu-entry {
  position: relative;
  display: block;
  cursor: pointer;
  margin-top: 0.2rem;

  &:hover {
    font-weight: bolder;
  }

}

.margin-top {
  margin-top: 0.8rem;
}
</style>