<template>
  <div
      class="score"
      v-tooltip.left="{content: $t('tt.scoreOfPlayer'), show: tutorial}">
    {{ score }}
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(['selectedPlayer']),
    score() {
      return this.selectedPlayer?.score || '-'
    }
  }
}
</script>

<style scoped>
.score {
  font-family: 'Luminari';
  font-weight: bold;
  font-size: xx-large;
}
</style>