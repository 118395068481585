<template>
  <div>
    <div
        v-for="player in players"
        :key="'player_' + player.number"
        class="entry"
        :class="{'selected': selectedPlayer && selectedPlayer.number === player.number}"
        @click="selectPlayer(player.number)">
      <icon
          class="icon"
          icon="user"
          size="1x"/>
      <span
          style="margin-left: 0.4em;"
          v-tooltip="{content: $t('tt.currentPlayer'), show: tutorial}">
        {{ player.name }}
      </span>
      <div class="score-circle">
        {{ player.score }}
      </div>
    </div>
    <div
        style="margin-left: 0.3rem;">
      <icon
          class="icon"
          icon="user-plus"
          @click="_addPlayer"
          v-tooltip.right="{content: $t('tt.addPlayers'), show: tutorial}"/>
    </div>
    <div
        v-if="undeadSuitInGame"
        style="margin-left: 1rem; cursor: pointer"
        class="entry"
        :class="{'selected': selectedPile === 0}"
        @click="_selectDiscard"
        v-tooltip.right="{content: $t('tt.discardIsHere'), show: showDiscardTooltip}">
      {{$t('discardPile')}}
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {suits} from '@/logic/cards'

export default {
  computed: {
    ...mapGetters(['allCards', 'tutorial', 'players', 'selectedPlayer', 'selectedPile']),
    undeadSuitInGame(){
      return this.allCards.find(c => c.suit === suits.Undead)
    },
    showDiscardTooltip(){
      return this.allCards.filter(c => c.suit === suits.Undead && c.pile > 0).length === 1
    }
  },
  methods: {
    ...mapActions(['addPlayer', 'selectPlayer', 'selectDiscard']),
    _addPlayer() {
      this.addPlayer(null).then(() => this.$emit('added'))
    },
    _selectDiscard(){
      this.selectDiscard().then(() => this.$emit('discardSelected'))
    }
  }
}
</script>

<style scoped>
.entry {
  margin-right: 0.5rem;
  cursor: pointer;
  opacity: 0.4;
}

.entry.selected {
  opacity: 1;
}

.score-circle {
  display: inline-flex;
  font-weight: bolder;
  font-family: Luminari sans;
  margin-left: 6px;
  margin-right: 6px;
  padding: 3px;
  border-radius: 5%;
  border: 1px solid white;
  text-decoration: none !important;
}

.icon {
  outline: none;
}
</style>